































































































import Embeddable from '@/components/Common/Embeddable.vue';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import { Component, Mixins } from 'vue-property-decorator';

import { BlogFilter, BlogOrderBy, ListingFilter, TestimonialFilter, TestimonialOrderBy } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Profile,
  WebsiteLevel,
  BlogType,
} from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';

import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';
import Blogs from '@/components/Blogs.vue';
import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

@Component({
  components: {
    Listings,
    SideBySide,
    ItemCycler,
    Embeddable,
    Blogs,
    TestimonialCycler,
  },
})
export default class Home extends Mixins(View) {
  private profile: Profile | null = null;

  private testimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Profile,
    SearchGuid: Config.Website.Settings!.WebsiteId,
    OrderByStatements: [TestimonialOrderBy.CreateDateDesc],
  });

  private blogFilter = new BlogFilter({
    Types: [BlogType.Social],
  });

  private readonly videoEmbed: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: '1w4bPfIB_jA',
  }

  private listingSaleFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
    ],
    PageSize: 3,
  });

  private listingSoldFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Sold,
    ],
    PageSize: 3,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  mounted() {
    API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
      this.profile = profile;
    });
  }
}
